import React, { ReactElement } from 'react';
import "./styles/ProjectDetails.scss";

export default function FSIRecipeEditor(): ReactElement {
    return (
        <div className="p-2 text-justify">
            <div key="0" className="text-center p-3" style={{ fontSize: "2em", fontWeight: 300 }}>
                FSI Mercury Recipe Editor
            </div>
            <p key="1">
                The original FSI Mercury only allowed recipe editing on the tool itself. This is through
                a gruelling process of using a '90s-era touchscreen with a 40-character line limit on a
                486 cpu.
            </p>
            { example("fsi_interface.png", "Original interface of the FSI Mercury.") }
            <p key="2">
                A few years later, undoubtedly driven at least partially by a loudly complaining user base,
                FSI released an offline editor for Windows. This improved the recipe creation process, but
                when your recipes are a minimum of 20 lines, it can still be maddeningly slow to work on.
            </p>
            <p key="3">
                Originally starting as a solution to help migrate the longest OEM process to our FSI Mercury fleet,
                clocking in at over 100 lines, I started building a recipe editor in the most accessible software interface
                at the time, both for me and my compatriot process engineers: Excel.
            </p>
            { example("fsi_new_editor_home.png", "The newly developed recipe editor.") }
            <p key="4">
                It started as a bit of a pet project, but ended up being extremely valuable both in times of
                immediate need (e.g. immediate development of a heavy SC2 tool clean when we had elevated metal particles)
                and for developing long-term maintenance recipes (e.g. tool and exhaust clean recipes that 
                allow maintenance or operators to set and forget).
            </p>
            { example("fsi_new_editor_decon.png", "Additional functions include adding/deleting/moving multiple lines at a time. All Excel functionality still available within the recipe cells.") }
            <p key="5">
                This editor leverages the best of Excel and VBA to deliver a product I am very proud of. 
                It allows rapid-fire editing in an interface that everybody is familiar with, Excel,
                while adding in tools that help speed up recipe development even more. There are standard 
                add / modify / delete methods as well as an easy copy disk-to-disk method.
            </p>
        </div>
    );
}

function example(image_src: string, caption_text?: string) {
    const children = [];

    children.push(<img src={ "/images/projects/" + image_src } alt="" />)

    if (caption_text !== "") {
        children.push(<br />)
        children.push(<span>{ caption_text }</span>)
    }

    return (
        <p className={ "ProjectImage text-center" } children={ children } />    
    );
}