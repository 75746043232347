export type ProjectStruct = {
    name: string,
    image?: string,
    languages: string[],
    languageStyle?: {},
    detail: string,
    company?: string,
    roles?: string[],
    url: string,
    external?: boolean,
}

let projects: ProjectStruct[] = [
    {
        name: "FSI Mercury Recipe Editor",
        image: "/images/projects/fsi_mercury_interface.jpg",
        languages: ["Excel VBA", "Excel Macros", "FSI Mercury"],
        detail: "Fully revamped and significantly improved FSI Mercury recipe editor, adding various new functionalities.",
        company: "Analog Devices, Inc",
        roles: ["Equipment Engineer"],
        url: "/fsi_recipe_editor",
        external: false,
    },
    {
        name: "2003 Honda Accord",
        image: "/images/projects/2003_honda_accord.jpg",
        languages: [],
        detail: "Ongoing upgrades and maintenance to keep a happy car happy.",
        roles: ["Personal project"],
        url: "/2003_honda_accord",
        external: false,
    },
    {
        name: "Multi-Pass Compiler / Transpiler",
        image: "/images/projects/compiler.jpg",
        languages: ["Java", "x86 Asssembly", "Python"],
        detail: "Compiles into x86 assembly for Windows and transpiles into Python.",
        roles: ["Personal project"],
        url: "https://github.com/ZacharyYGateley/Compiler",
        external: true,
    },
    {
        name: "Autonomous Target-Shooting Robot",
        image: "/images/projects/autonomous-robot.jpg",
        languages: ["SolidWorks", "LabVIEW"],
        languageStyle: {
            background: "#444c",
            border: "1px solid #000c",
            color: "#fff",
        },
        detail: "Autonomously traverses an obstacle course, finds and shoots a target, then returns to its original position.",
        roles: ["Lead design engineer", "State-space engineer"],
        url: "https://www.mae.ucla.edu/two-mae-student-teams-are-finalists-in-the-2018-asme-sme-student-manufacturing-design-competition-smdc/",
        external: true,
    },
    {
        name: "Generalized Organizer",
        image: "/images/projects/organizer-3.png",
        languages: ["JavaScript", "CSS3"],
        detail: "Standardized-API organizational tool for interacting with any data source.",
        company: "Cykic Software, Inc",
        roles: ["Senior developer"],
        url: "/organizer", 
    }
];

export default projects;