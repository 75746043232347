import React from 'react';
import { Nav, Navbar, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './styles/social-icons.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';



let printOK = document.createElement("link");
printOK.setAttribute("href", "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css");
printOK.setAttribute("rel", "stylesheet");
printOK.setAttribute("media", "screen");
document.getElementsByTagName("head")[0].appendChild(printOK);

export default class NavBar extends React.Component {
    navRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            navExpanded: false,
        }
    }

    setNavExpanded(expanded) {
        this.setState({
            navExpanded: expanded,
        })
    }

    closeNav() {
        this.setNavExpanded(false);
    }

    componentDidMount() {
        this.componentDidUpdate()
        const nav = this.navRef.current;
        nav.style.height = nav.offsetHeight + "px";
        nav.setAttribute("data-height", nav.style.height);
        nav && nav.setAttribute("data-height", nav.style.height);
    }

    componentDidUpdate() {
        const nav = this.navRef.current;
        if (nav) {
            if (this.props.hideAll) {
                nav.style.height = nav.offsetHeight + "px";
                nav.setAttribute("data-height", nav.style.height);
                nav.style.overflow = "hidden";
            }
            else {
                window.setTimeout(() => nav.style.overflow = "visible", this.props.transitionTime);
            }
            nav.style.height = (this.props.hideAll ? "0" : nav.getAttribute("data-height"));
            nav.style.opacity = (this.props.hideAll ? "0" : "1");
        }
    }

    render() {
        return (
        <div ref={ this.navRef } className="Navigation fixed-top">
            <Navbar bg="light" expand="lg" style={{ zIndex: "1" }}
                onToggle={ (expanded) => this.setNavExpanded(expanded) }
                expanded={ this.state.navExpanded }>
                <Navbar.Brand href="#home">
                    <img src="/images/logo-30.png"
                        height="30"
                        width="55"
                        className="d-inline-block align-top"
                        style={{ margin: "0 1em" }}
                        alt="" />
                    Zach Gateley
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto" onSelect={ () => this.closeNav() }>
                        <Link to="/home" className="nav-link" 
                            onClick={ () => this.closeNav() }>Home</Link>
                        <Link to="/projects" className="nav-link"
                            onClick={ () => this.closeNav() }>Projects</Link>
                        <Link to="/cv" className="nav-link"
                            onClick={ () => this.closeNav() }>CV</Link>
                    </Nav>
                    <Form inline>
                        <a href="http://linkedin.com/in/ZacharyGateley" target="_blank" rel="noopener noreferrer nofollow" className="LinkedIn"><div></div><div></div></a>
                        <a href="http://github.com/ZacharyYGateley" target="_blank" rel="noopener noreferrer nofollow" className="GitHub"><div></div><div></div></a>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
        </div>
        );
    }
}