import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, useLocation } from 'react-router-dom';
import './styles/index.scss';
import * as serviceWorker from './serviceWorker';
import App from "./App";

ReactDOM.render(
  <Router >
    <ScrollToTop />
    <App />
  </Router>,
  document.getElementById('root')
);


export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
