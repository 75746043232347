import React, { ReactElement } from 'react';
import { Location } from "./dataInterface";

const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
];

const states: { [index:string] : string } = {};
states["California"] = "CA";
states["Colorado"] = "CO";
states["Illinois"] = "IL";

export class OrganizationHeader extends React.Component<{
        uniqueKey: any,
        organization: string,
        link?: string,
        location: Location,
    }, any> {

    render() {
        let link: ReactElement = null as unknown as ReactElement;
        if (this.props.link) {
            link = (
                <div key="1" className="Header2 Nowrap ExternalLink FlexColumn">
                    (
                    <a key="0" href={ this.props.link } target="_blank" rel="noopener noreferrer nofollow">{ this.props.link }</a>
                    )
                </div>
            );
        }

        return (
            <div key={ this.props.uniqueKey } className="FlexRow">
                <div key="0" className="Header2 Left Nowrap FlexColumn" style={{ marginRight: "auto" }}>
                    { this.props.organization }
                </div>
                { link }
                <div key="2" className="Header2 Right Nowrap FlexColumnLast OrgLoc0">
                    { locationToString(this.props.location) }
                </div>
            </div>
        );
    }
}

export class RoleHeader extends React.Component<{
        uniqueKey: any,
        startDate?: Date,
        endDate?: Date,
        role: string
    }, any> {

    render() {
        let dateSpan: string = "";
        if (this.props.startDate) {
            dateSpan += formatDate(this.props.startDate);
            dateSpan += " — ";
        }
        if (this.props.endDate) { 
            dateSpan += formatDate(this.props.endDate);
        }
        else {
            dateSpan += "Present";
        }

        return (
            <div key={ this.props.uniqueKey+".1" } className="Container FlexRow">
                <div key="0" className="Header3 Nowrap FlexColumn" style={{ marginRight: "auto" }}>
                    { this.props.role }
                </div>
                <div key="1" className="Header3 Nowrap FlexColumnLast">
                    { dateSpan }
                </div>
            </div>
        );
    }
}

export function formatDate(date: Date | undefined): string {
    if (!date) return "";

    return months[date.getMonth()] + " " + date.getFullYear();
}

export function getCSSClass(classSelector: string): CSSStyleRule | null {
    for (let ss of Array.from(document.styleSheets) as CSSStyleSheet[]) {
        try {
            for (let cssRule of Array.from(ss.cssRules) as CSSStyleRule[]) {
                if (cssRule && cssRule.selectorText && cssRule.selectorText === classSelector) {
                    return cssRule;
                }
            }
        }
        catch (err) {
            // Some ss may be xss
        }
    }
    return null;
}

export function locationToString(location: Location): string {
    if (!location.minor) {
        return location.major;
    }
    else {
        let major = location.major;
        if (states.hasOwnProperty(major)) {
            major = states[major];
        }
        return location.minor + ", " + major;
    }
}