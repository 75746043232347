import React from 'react';
import { Location } from 'history';
import { Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';


// Pages
import Accord2003 from './projects/Accord2003';
import FSIRecipeEditor from './projects/FSIRecipeEditor';
import Home from "./Home";
import Projects from "./projects/Projects";
import Organizer from "./projects/Organizer";
import ResumeHandler from "./resume/ResumeHandler";

type PageProps = { 
    hideAll: boolean,
    setHideAll: Function,
    location: Location,
    transitionTime: number,
}

let hideAll: boolean;
let pageRef: React.RefObject<HTMLDivElement> = React.createRef();
let resizeTimer: number;

export default class Page extends React.Component<PageProps, any> {

    componentDidMount() {
        this.componentDidUpdate();

        window.addEventListener("resize", calculatePageHeight);
        resizeTimer = window.setInterval(calculatePageHeight, 333);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", calculatePageHeight);
        window.clearInterval(resizeTimer);
    }

    componentDidUpdate() {
        calculatePageHeight();
        hideAll = this.props.hideAll;
    }

    render() {
        let setHideAll = this.props.setHideAll;
        let location = this.props.location;
        let transitionTime = this.props.transitionTime;
        return (
            <div ref={ pageRef } className="Page">
                <TransitionGroup className="PageContent">
                    <CSSTransition
                        key={ location.pathname }
                        classNames="fade"
                        timeout={ transitionTime }
                    >
                        <div>
                            <Switch location={ location }>
                                <Route exact 
                                    path="/home" 
                                    render={ () => <Home /> } />
                                <Route
                                    path="/2003_honda_accord"
                                    render={ () => <Accord2003 />}  />
                                <Route 
                                    path="/cv" 
                                    render={ () => <div><ResumeHandler handleHideAll={ setHideAll } /></div> } />
                                <Route
                                    path="/fsi_recipe_editor"
                                    render={ () => <FSIRecipeEditor /> } />
                                <Route 
                                    path="/projects" 
                                    render={ () => <Projects /> } />
                                <Route
                                    path="/organizer"
                                    render={ () => <Organizer /> } />
                                <Route 
                                    path="/" 
                                    render={() => <Redirect to="/home" /> } />
                            </Switch>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
    }
}

export function calculatePageHeight() {
    if (!pageRef || typeof hideAll === "undefined") return;

    const page = pageRef.current as HTMLDivElement;
    if (page && page.firstElementChild && page.firstElementChild.firstElementChild) {
        const pageContent = (page.firstElementChild.firstElementChild as HTMLElement);
        const pageEl = (page as HTMLElement);
        // Size page after all other hooks have fired
        window.setTimeout((function(pageEl, pageContent) {
            return function() {
                const height = pageContent.offsetHeight + "px";
                pageEl.style.height = height;
            }
        })(pageEl, pageContent), 0);
        pageEl.style.marginBottom = (hideAll ? "0" : "");
        pageEl.style.overflow = "visible";
    }
}