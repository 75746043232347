import React, { ReactElement } from "react";
import { Hd, Ed, EdO, ExB, Ex, SkT, Sk, Bl } from "./dataInterface";
import Header from "./Header";
import EducationOrganization from "./Education";
import ExperienceType from "./Experience";
import SkillType from "./Skills";
import "./styles/Resume.scss";
import { Filters, ResumeArguments } from "./ResumeHandler";
import FineTune from "./FineTune";

class Block extends React.Component<Bl & ResumeArguments, any> {
    children: ReactElement<any>[] = [];

    title() {
        if (isActive(this.props.title, this.props.filters)) {
            const uniqueId = "ttl." + this.props.uniqueId;
            const header = (
                <React.Fragment>
                    <div key={ 0 } className="Header1 Highlight">{ this.props.title?.name }</div>
                    <div key={ 1 } className="HorizontalDivider1"></div>
                </React.Fragment>
            );
            this.children.push(
                <FineTune 
                    key={ uniqueId }
                    fineTune={ !!this.props.filters.fineTune }
                    isActive={ true }
                    uniqueId={ uniqueId }
                    children={ header } />
            );
        }
    }

    header() {
        if (isActive(this.props.header, this.props.filters)){
            const uniqueId = "hdr." + this.props.uniqueId;
            const props = Object.assign(
                {}, 
                this.props.header, 
                { 
                    key: uniqueId,
                    uniqueId: uniqueId,
                    filters: this.props.filters,
                },
            );
            this.children.push(
                React.createElement(Header, props, [])
            );
        }
    }

    education() {
        let edo = this.props.education || [];
        for (let i = 0; i < edo.length; i++) {
            if (isActive(edo[i], this.props.filters)) {
                const uniqueId = "ed." + this.props.uniqueId + "." + i;
                const props = Object.assign(
                    {}, 
                    edo[i], 
                    { 
                        key: uniqueId,
                        uniqueId: uniqueId,
                        filters: this.props.filters,
                    },
                );
                this.children.push(
                    React.createElement(EducationOrganization, props, [])
                );
            }
        }
    }

    experience() {
        let ex = this.props.experience || [];
        for (let i = 0; i < ex.length; i++) {
            if (isActive(ex[i], this.props.filters)) {
                const uniqueId = "ex." + this.props.uniqueId + "." + i;
                const props = Object.assign(
                    {}, 
                    ex[i], 
                    { 
                        key: uniqueId,
                        uniqueId: uniqueId,
                        filters: this.props.filters,
                    },
                );
                this.children.push(
                    React.createElement(ExperienceType, props, null)
                );
            }
        }
    }

    skills() {
        // Get updated list of skills
        let skt = (this.props.skills || []).filter(sk => isActive(sk, this.props.filters));

        for (let i = 0; i < skt.length; i++) {
            //const uniqueBase = (this.props.skills && this.props.skills[i] ? this.props.skills[i].name : "");
            const uniqueId = "sk." + this.props.uniqueId + "." + i;
            const props = Object.assign(
                {}, 
                skt[i], 
                { 
                    key: uniqueId,
                    uniqueId: uniqueId,
                    filters: this.props.filters,
                    topLevelSkills: skt.length,
                    rerender: Math.random()
                },
            );
            this.children.push(
                React.createElement(SkillType, props, null)
            );
        }
    }

    render() {
        // Children are a result of props,
        // So they do not have to be a part of props
        while (this.children.length > 0) this.children.pop();

        this.title();
        this.header();
        this.education();
        this.experience();
        this.skills();

        if (!this.children.length) return <React.Fragment key={ this.props.uniqueId } />

        return React.createElement("div", { key: this.props.uniqueId, className: "Block" }, this.children);
    }
}

export default class Resume extends React.Component<any, any> {

    // Post render events
    componentDidMount() {
        // Align all labeled columns (EdCol0, ...)
        const edCols = [ "EdCol", "OrgLoc" ];
        for (let col of edCols) {
            let i = 0;
            while (true) {
                // Element collection to array
                let cols: HTMLElement[] = [].slice.call( document.getElementsByClassName(col + i) );
                if (!cols || !cols.length) break;

                // Clear out current widths
                cols.map(c => c.style.width = "");

                // Get max width from every same column
                let maxWidth = 0;
                for (let j = 0; j < cols.length; j++) {
                    const col = cols[j];
                    maxWidth = Math.max(maxWidth, parseInt(window.getComputedStyle(col).width));
                }
                // For good measure
                maxWidth += 12;

                // Set width on every same column
                cols.map(c => c.style.width = maxWidth + "px");

                i++;
            }
        }
    }

    componentDidUpdate() {
        this.componentDidMount();
    }

    render() {
        let blocks: ReactElement[] = [];
        for (let i = 0; i < this.props.cv.length; i++) {
            let component = this.props.cv[i];
            if (isActive(component, this.props.filters)) {
                const props = Object.assign(
                    {}, 
                    component, 
                    { 
                        key: i,
                        uniqueId: i,
                        filters: this.props.filters,
                    },
                );
                blocks.push(React.createElement(Block, props, null));
            }
        }

        return React.createElement(React.StrictMode, {}, blocks);
    }
}

export function isActive(component: (Hd | Ed | EdO | ExB | Ex | SkT | Sk | Bl) & any, props: Filters): boolean {
    let active = true;
    // Check for null
    active = active && !!component;
    // Profile filter
    active = active && (component.profiles.indexOf(props.profile) > -1);
    return active
}

export function getOrganizationHeader(name: string, location: string) {
    
}