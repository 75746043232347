

export enum DegreeType {
    AA="Associate of Art",
    BS="Bachelor of Science",
    G="Graduate Coursework",
    MS="Master of Science",
    M="Master",
}

export enum LatinHonors {
    cumLaude="cum laude",
    magnaCumLaude="magna cum laude",
    summaCumLaude="summa cum laude"
}

export type Location = {
    minor?: string;
    major: string;
}

export enum Profile {
    cv="CV",
    software="Software Development",
    engineering="Engineering",
}
/*export function profileKey(profile: string): Profile {
    let P: 
}*/
export function profileValue(profile: string): string {
    let P: { [idx: string]: Profile } = Profile;
    return P[profile];
};

// Resume Header

export type Hd = {
    header: string;
    subheaders: string[];
    profiles: Profile[];
}

// Block headers

export type Tl = {
    name: string;
    profiles: Profile[];
}

// Education

export type Ed = {
    endDate: Date;
    isExpected: boolean;
    course?: string;
    degreeType?: DegreeType;
    degreeSubject?: string;
    gpa?: string;
    latinHonors?: LatinHonors;
    additionalDetails?: string;
    profiles: Profile[];
}

export type EdO = {
    name: string;
    location: Location;
    profiles: Profile[];
    items: Ed[];
}

// Experience

export type ExB = {
    text: string;
    profiles: Profile[];
}

export type Ex = {
    organization: string;
    location: Location;
    name: string;
    URL?: string;
    startDate?: Date;
    endDate?: Date;
    additionalDetails?: any;
    profiles: Profile[];
    items?: ExB[];
}

// Skills

export type Sk = {
    name: string;
    profiles: Profile[];
}

export type SkT = {
    name: string;
    profiles: Profile[];
    items: Sk[],
}

// Top level

export type Bl = {
    title?: Tl,
    header?: Hd,
    education?: EdO[],
    experience?: Ex[],
    skills?: SkT[],
    profiles: Profile[],
}

export type CV = Bl[];