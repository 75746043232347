import React, { useEffect } from 'react';
import { History, Location } from 'history';
import './styles/App.scss';
import './styles/transitions.scss';
import NavBar from "./NavBar.js";
import Page from "./Page";
import Footer from "./Footer";
import { useLocation } from 'react-router-dom';


const transitionTime: number = 400;
let firstTimeHome = true;

function App() {
  const [hideAll, setHideAll]  = React.useState(false);
  const location: Location<History.UnknownFacade> = useLocation();

  // Printer friendly view from resume
  useEffect(() => {
    const app: HTMLElement = (document.getElementsByClassName("App") || [null as unknown])[0] as HTMLElement;
    if (app) {
      app.style.marginBottom = (hideAll ? "0" : "");
    }
    const menu: HTMLDivElement = (document.getElementsByClassName("Menu") || [null as unknown])[0] as HTMLDivElement;
    if (menu) {
      menu.style.padding = (hideAll ? "0" : "");
    }
    const placeholder: HTMLElement = (document.getElementsByClassName("NavigationPlaceholder") || [null as unknown])[0] as HTMLElement;
    if (placeholder) {
      placeholder.style.height = (hideAll ? "0" : "");
    }
  }, [hideAll]);

  return (
    <div className="App">
      <NavBar hideAll={ hideAll } transitionTime={ transitionTime } />
      <div className="NavigationPlaceholder"></div>

      <Page hideAll={ hideAll } location={ location } setHideAll={ setHideAll } transitionTime={ transitionTime } />

      <Footer hideAll={ hideAll } transitionTime={ transitionTime } />
    </div>
  );
}

export function isFirstTimeHome() {
  const firstTime = firstTimeHome;
  firstTimeHome = false;
  return firstTime;
}

export default App;
