import React, { ReactElement, ChangeEvent, RefObject } from 'react';
import { Profile, profileValue } from "./dataInterface";
import { ButtonGroup, ToggleButton, InputGroup, FormControl, ListGroup } from 'react-bootstrap';

export default class Menu extends React.Component<any, any> {
    bulletPointRef: RefObject<HTMLInputElement> = React.createRef();
    headerMarginRef: RefObject<HTMLInputElement> = React.createRef();
    minColumnsRef: RefObject<HTMLInputElement> = React.createRef();
    maxColumnsRef: RefObject<HTMLInputElement> = React.createRef();

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        this.props.events.handleProfile(event.currentTarget.value);
    }

    handleMinColumns() {
        const minEl = this.minColumnsRef.current;
        const maxEl = this.maxColumnsRef.current;
        if (minEl && maxEl) {
            if (parseInt(minEl.value) > parseInt(maxEl.value)) {
                maxEl.value = minEl.value;
                this.props.events.handleStateChange("maxSkillColumns", maxEl.value);
            }
            this.props.events.handleStateChange("minSkillColumns", minEl.value);
        }
    }

    handleMaxColumns() {
        const minEl = this.minColumnsRef.current;
        const maxEl = this.maxColumnsRef.current;
        if (minEl && maxEl) {
            if (parseInt(minEl.value) > parseInt(maxEl.value)) {
                minEl.value = maxEl.value;
                this.props.events.handleStateChange("minSkillColumns", minEl.value);
            }
            this.props.events.handleStateChange("maxSkillColumns", maxEl.value);
        }
    }

    handleStyleChange(className: string, styleName: string, styleUnit: string, target: HTMLInputElement) {
        if (target) {
            this.props.events.handleStyleChange(className, styleName, (target.value + styleUnit));
        }
    }

    render() {
        return (
        <React.Fragment>
            <div key="1" className="MenuBody">
                <div className="MenuContent FlexRow">
                    <div key="profile" className="MenuItem">
                        { getProfileOptions(this.props.events.handleStateChangeRefresh, this.props.filters.profile, this) }
                    </div>
                    <div key="view" className="MenuItem">
                        <ViewOptions events={ this.props.events } />
                    </div>
                    <div key="fineTune" className="MenuItem">
                        <FineTuning events={ this.props.events } parent={ this } />
                    </div>
                </div>
            </div>
        </React.Fragment>
        );
    }
}

function getProfileOptions(handler: Function, currentProfile: Profile, self: Menu): ReactElement {
    let options: ReactElement[] = [];
    let profileKeys: { key: string, value: string }[] = [];
    for (const profile in Profile) {
        profileKeys.push({
            key: profile,
            value: profileValue(profile),
        })
    }
    for (const { key, value } of profileKeys) {
        options.push(
            <ListGroup.Item key={ key + ".lg" }>
                <label key={ key } style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input type="radio" name="profile" checked={ (currentProfile === value ? true : false) }
                            onChange={ () => handler("profile", value) } />
                    </div>
                    <div style={{ display: "flex" }}>
                        { value }
                    </div>
                </label>
            </ListGroup.Item>
        );
    }
    return (
        <React.Fragment>
            <div key="profile0" className="MenuSubheader">
                Profiles
            </div>
            <div key="profile1" className="MenuSubcontent">
                <ListGroup>
                    { options }
                </ListGroup>
            </div>
        </React.Fragment>
    );
}

class ViewOptions extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            printerFriendly: false,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div key="view0" className="MenuSubheader">
                    View Style
                </div>
                <div key="view1" className="MenuSubcontent btn-block btn-group-toggle" data-toggle="buttons">
                    <ButtonGroup toggle className="btn-block">
                        <ToggleButton
                            type="checkbox"
                            variant={ (this.state.printerFriendly ? "primary" : "outline-primary") }
                            checked={ false }
                            value="1"
                            block={ true }
                            onChange={ (event: ChangeEvent<HTMLInputElement>) => {
                                this.props.events.handlePrinterFriendly(!this.state.printerFriendly);
                                this.setState({ printerFriendly: !this.state.printerFriendly });
                                }
                            }
                        >
                        Printer Friendly
                        </ToggleButton>
                    </ButtonGroup>
                </div>
            </React.Fragment>
        );
    }
}

class FineTuning extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            editMode: false,
            compactHeader: false,
        }
    }

    render() {
        const parent = this.props.parent;

        return (
            <React.Fragment>
                <div key="view0" className="MenuSubheader">
                    Fine Tuning
                </div>
                <div key="view1" className="MenuSubcontent">
                    <div key="0" className="btn-block btn-group-toggle" data-toggle="buttons">
                        <ButtonGroup toggle className="btn-block">
                            <ToggleButton
                                type="checkbox"
                                variant={ (this.state.editMode ? "primary" : "outline-primary") }
                                checked={ false }
                                value="1"
                                block={ true }
                                onChange={ (event: ChangeEvent<HTMLInputElement>) => {
                                    this.props.events.handleFineTuning(!this.state.editMode); 
                                    this.setState({ editMode: !this.state.editMode });
                                }}
                            >
                                Edit Mode<br />
                                <div className="MenuInfo Nowrap" style={{ color: (this.state.editMode? "#fff" : ""), marginLeft: "24px" }}>
                                    Click individual entries to enable/disable
                                </div>
                            </ToggleButton>
                        </ButtonGroup>
                    </div>
                    <div key="1" className="btn-block btn-group-toggle" data-toggle="buttons">
                        <ButtonGroup toggle className="btn-block">
                            <ToggleButton
                                type="checkbox"
                                variant={ (this.state.compactHeader ? "primary" : "outline-primary") }
                                checked={ false }
                                value="1"
                                block={ true }
                                onChange={ () => {
                                    this.props.events.handleStateChangeRefresh("compactHeader") 
                                    this.setState({ compactHeader: !this.state.compactHeader });
                                }}
                            >
                                Compact Header
                            </ToggleButton>
                        </ButtonGroup>
                    </div>
                    <div>
                        <span>Number of Skill Columns</span>
                        <div className="FlexRow">
                            <div className="Nowrap Flex MenuInput">
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Min:</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl 
                                        ref={ parent.minColumnsRef } 
                                        type="number" 
                                        defaultValue={ parent.props.filters.minSkillColumns } 
                                        onChange={ () => parent.handleMinColumns() } />
                                    <InputGroup.Append>
                                        <InputGroup.Text>px</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                {/*
                                Min: <input ref={ parent.minColumnsRef } type="number" defaultValue={ parent.props.filters.minSkillColumns } 
                                    onChange={ () => parent.handleMinColumns() } /> */}
                            </div>
                            <div className="Nowrap Flex MenuInput">
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Max:</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl 
                                        ref={ parent.maxColumnsRef } 
                                        type="number" 
                                        defaultValue={ parent.props.filters.maxSkillColumns } 
                                        onChange={ () => parent.handleMaxColumns() } />
                                    <InputGroup.Append>
                                        <InputGroup.Text>px</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                {/*
                                Max: <input ref={ parent.maxColumnsRef } type="number" defaultValue={ parent.props.filters.maxSkillColumns } 
                                onChange={ () => parent.handleMaxColumns() } /> */}
                            </div> 
                        </div>
                    </div>
                    <div>
                        <span>Block Header Margins</span>
                        <div className="FlexRow">
                            <div className="Nowrap Flex MenuInput">
                                <InputGroup size="sm">
                                    <FormControl 
                                        type="number" 
                                        defaultValue={ 4 } 
                                        onChange={ (event: ChangeEvent) => {
                                            parent.handleStyleChange(".Header2", "marginTop", "px", event.currentTarget as HTMLInputElement) 
                                        }} />
                                    <InputGroup.Append>
                                        <InputGroup.Text>px</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                {/*
                                <input ref={ parent.headerMarginRef }type="number" defaultValue={ 4 }
                                    onChange={ (event: ChangeEvent) => {
                                        parent.handleStyleChange(".Header2", "marginTop", "px", event.currentTarget as HTMLInputElement) 
                                    }} /> px
                                */}
                            </div>
                        </div>
                    </div>
                    <div>
                        <span>Bullet Point Indentation</span>
                        <div className="FlexRow">
                            <div className="Nowrap Flex MenuInput">
                                <InputGroup size="sm">
                                    <FormControl 
                                        ref={ parent.bulletPointRef }
                                        type="number" 
                                        defaultValue={ 30 } 
                                        onChange={ (event: ChangeEvent) => {
                                            parent.handleStyleChange(".Bullet", "padding-inline-start", "px", event.currentTarget as HTMLInputElement) 
                                        }} />
                                    <InputGroup.Append>
                                        <InputGroup.Text>px</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                            {/*
                            <div className="Nowrap Flex MenuInput">
                                <input ref={ parent.bulletPointRef }type="number" defaultValue={ 30 }
                                    onChange={ (event: ChangeEvent) => {
                                        parent.handleStyleChange(".Bullet", "padding-inline-start", "px", event.currentTarget as HTMLInputElement) 
                                    }} /> px
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}