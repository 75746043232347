import React, { ReactElement } from 'react';
import { Redirect } from 'react-router';
import projects, { ProjectStruct } from "./data";
import { Button, Card } from 'react-bootstrap';
import "./styles/Projects.scss";

class Project extends React.Component<ProjectStruct & {
    redirect: Function,
}, any> {
    componentDidMount() {
        document.title = "Zach Gateley - Projects";
    }

    handleClick() {
        if (this.props.external) {
            const a = document.createElement("a");
            a.setAttribute("href", this.props.url); 
            a.setAttribute("target", "_blank");
            a.setAttribute("rel", "noopener noreferrer");
            a.click();
        }
        else {
            this.props.redirect(this.props.url);
        }
    } 

    render() {
        // Roles
        const roles: ReactElement[] = [];
        const company = this.props.company;
        if (company) {
            roles.push(
                <React.Fragment key={ company }>
                    <div key={ company } className="Company">{ company }</div>
                    &nbsp;
                </React.Fragment>
            );
        }
        const rls = this.props.roles || [];
        for (let i = 0; i < rls.length; i++) {
            roles.push(
                <React.Fragment key={ rls[i] }>
                    <div key={ rls[i] } className="Role">{ rls[i] }</div>
                    &nbsp;
                </React.Fragment>
            );
        }

        // Languages
        const languages: ReactElement[] = [];
        const lang = this.props.languages;
        for (let i = 0; i < lang.length; i++) {
            let style = {};
            if (this.props.languageStyle) {
                style = this.props.languageStyle;
            }
            languages.push(
                <React.Fragment key={ lang[i] }>
                    <div key={ lang[i] } className="Language" style={ style }>{ lang[i] }</div>
                    &nbsp;
                </React.Fragment>
            );
        }

        return (
            <div style={{ display: "flex", padding: "1rem" }}>
                <Card style={{ width: "18rem", position: "relative", paddingBottom: "68px" }}>
                    <div style={{ position: "relative" }}>
                        <Card.Img variant="top" src={ this.props.image } />
                        <div className="RolesContainer">{ roles }</div>
                        <div className="LanguagesContainer">{ languages }</div>
                    </div>
                    <Card.Body style={{ paddingBottom: "0" }}>
                        <Card.Title>{ this.props.name }</Card.Title>
                        <Card.Text>
                            { this.props.detail }
                        </Card.Text>
                        <Button variant="secondary" style={{ position: "absolute", bottom: "20px", left: "20px" }} onClick={ () => this.handleClick() }>
                            See Project
                        </Button>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default function Projects(): ReactElement {
    let [redirect, setRedirect] = React.useState("");
    if (redirect) {
        return <Redirect to={ redirect } />
    }

    const children = [];
    for (const project of projects) {
        children.push(
            <Project key={ project.name } {...project} redirect={ setRedirect } />
        );
    }

    return (
        <div>
            <div className="text-center pt-3" style={{ fontSize: "2.7em", fontWeight: 300 }}>
                Selected Projects
            </div>
            <div style={{ margin: "0 auto" }}>
                <div className="ProjectsContainer">
                    { children }
                </div>
            </div>
        </div>
    );
}