import React, { ReactElement } from 'react';
import "./styles/ProjectDetails.scss";

export default function Organizer(): ReactElement {
    return (
        <div className="p-2 text-justify">
            <div key="0" className="text-center p-3" style={{ fontSize: "2em", fontWeight: 300 }}>
                Generalized Organizer
            </div>
            <p key="1">
                { example(false) }
                The company's generalized interactive data interface had been developed in the early days 
                of the internet and was begging for some of the bells and whistles of 
                more recent web developments. At this small company, 
                after I had brought up redevelopment enough with my manager, we went underway.
                She handled the majority of the backend API work, and I handled the majority of 
                the front-end design and development.
            </p>
            <p key="3">
                { example(true) }
                Ultimately, we took the tool from a hard-coded functional design to
                an object-oriented, generalized design, where any plugin can use the so-called
                "Organizer" as it needs. All information is loaded on the fly via AJAX; 
                items may be dragged and dropped among eachother for reordering or
                other purposes; due to the OOP design, organizers may be infinitely nested;
                and if none of these capabilities is sufficient, there are several customizable
                hooks for whatever the specific data set may demand.
            </p>
            <p key="4">
                This is only one of the many projects that I leave behind with Cykic Software
                and its clients. No doubt, I have learned a lot since
                completing this project. There are plenty of development decisions that I 
                would make differently now. Nevertheless, I am proud to have created an integral
                part of the company's core software and have become a better developer for it.
            </p>
        </div>
    );
}

function example(mobile: boolean) {
    return (
    <p className={ "ProjectImage SmallRightImage " + (mobile ? "ProjectMobile" : "ProjectDesktop") + " text-center" }>
        <img src="/images/projects/organizer.png" alt="" />
        <br />
        <span>Example of a multi-module organizer</span>
    </p>
    );
}