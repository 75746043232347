import React, { ReactElement } from "react";
import { Ex, ExB } from "./dataInterface";
import { OrganizationHeader, RoleHeader } from "./Utils";
import { isActive } from "./Resume";
import { ResumeArguments } from "./ResumeHandler";
import FineTune from "./FineTune";

class ExperienceBullet extends React.Component<ExB & ResumeArguments, any> {
    render() {
        return (
            <ul key={ this.props.uniqueId} className="Bullet"> 
                <li key={ this.props.uniqueId }>
                    { this.props.text }
                </li>
            </ul>
        );
    }
}

export default class ExperienceType extends React.Component<Ex & ResumeArguments, any> {
    getChildren(): ReactElement[] {
        // Empty this.children and rebuild
        let children: ReactElement[] = [];
        while (children.length > 0) children.pop();
        const items = this.props.items || [];
        for (let i = 0; i < items.length; i++) {
            if (isActive(items[i], this.props.filters)) {
                const uniqueId = this.props.uniqueId + "." + i;
                const props = Object.assign({}, items[i], { key: uniqueId, uniqueId: uniqueId, filters: this.props.filters });
                const bullet = React.createElement(ExperienceBullet, props, null)
                children.push(
                    <FineTune key={ uniqueId }
                        fineTune={ !!this.props.filters.fineTune }
                        isActive={ true }
                        uniqueId={ uniqueId }
                        children={ bullet } />
                );
            }
        }
        return children;
    }

    header(): ReactElement {
        return (
            <OrganizationHeader
                key={ this.props.uniqueId + ".0" }
                uniqueKey={ this.props.uniqueId + ".0" }
                organization={ this.props.organization }
                link={ this.props.URL }
                location={ this.props.location }
                />
        );
    }

    subheader(): ReactElement {
        return (
            <RoleHeader
                key={ this.props.uniqueId }
                uniqueKey={ this.props.uniqueId }
                startDate={ this.props.startDate }
                endDate={ this.props.endDate }
                role={ this.props.name} 
                />
        );
    }

    render() {
        // Rebuild children list and
        // Wrap children in container
        let children = this.getChildren() || <React.Fragment key={ this.props.uniqueId } />;
        const container = (
            <div key={ this.props.uniqueId+".2" } className="Container">
                { children }
            </div>
        );

        // Organization & location
        const header = this.header();

        // Title & date
        const subheader = this.subheader();

        const divider = <div key={ this.props.uniqueId + ".d" } className="HorizontalDivider2"></div>

        let details = <React.Fragment key={ this.props.uniqueId + ".3"}></React.Fragment>
        if (this.props.additionalDetails) {
            details = (<div key={this.props.uniqueId + ".4"} className="ResumeItem">
               { this.props.additionalDetails }
            </div>);
        } 

        const props = {
            className: "Block",
            style: {
                position: "relative",
            },
        };

        const uniqueId = this.props.uniqueId + ".ft";
        return (
            <FineTune key={ uniqueId }
                fineTune={ !!this.props.filters.fineTune }
                isActive={ true }
                uniqueId={ uniqueId }
                children={ [header, divider, subheader, details, container] }
                {...props} />
        );
    }
}