import React, { ReactElement } from "react";
import { Ed, EdO } from "./dataInterface";
import { formatDate, OrganizationHeader } from "./Utils";
import { isActive } from "./Resume";
import { ResumeArguments } from "./ResumeHandler";
import FineTune from "./FineTune";

class Education extends React.Component<Ed & ResumeArguments, any> {
    course(): ReactElement {
        // Do set EdCol1 on class title
        // Column widths are automatically adjusted by max width
        // Class names can be long and can mess up other styling
        return (
            <React.Fragment key={ this.props.uniqueId + ".0" }>
                <div key="0" className="FlexColumn Header3 EdCol0">Course,</div>
                <div key="1" className="FlexColumn Header3">{ this.props.course }</div>
            </React.Fragment>
        )
    }

    degree(): ReactElement {
        let columns: ReactElement[] = [];

        if (this.props.degreeType) {
            let text: string = this.props.degreeType || "";
            if (this.props.degreeSubject) {
                text += ",";
            }
            columns.push(
                <div key={ this.props.uniqueId + "." + columns.length } className={ "Flex Header3 EdCol" + (columns.length)}>
                    { text }
                </div>
            );
        }

        if (this.props.degreeSubject) {
            columns.push(
                <div key={ this.props.uniqueId + "." + columns.length } className={ "Flex Header3 EdCol" + (columns.length)}>
                    { this.props.degreeSubject }
                </div>
            );
        }

        let gpaText = "";
        if (this.props.gpa) {
            gpaText += "GPA: " + this.props.gpa;
        }
        let latinHonors: string = this.props.latinHonors || "";
        if (latinHonors) {
            latinHonors = " (" + latinHonors + ")"
        }
        if (gpaText || latinHonors) {
            columns.push(
                <div key={ this.props.uniqueId + "." + columns.length } className={ "Flex Header3 EdCol" + (columns.length) }>
                    &nbsp;&nbsp;&mdash;&nbsp;&nbsp;
                    { gpaText }
                    <span key="0" className="Latin">{ latinHonors }</span>
                </div>
            );
        }

        let uniqueId = this.props.uniqueId + ".0";

        return React.createElement(React.Fragment, { key: uniqueId }, columns);
    }

    endDate(): ReactElement {
        const text = 
            (this.props.isExpected ? "Expected " : "") + 
            formatDate(this.props.endDate);

        return (
            <div key={this.props.uniqueId + ".-1"} className="FlexLastColumn Header3 TransitionItem">
                <div key="0" className="Right" style={{ width: "100%" }}>
                    { text }
                </div>
            </div>
        );
    }

    render() {
        let leftHeader;
        if (this.props.course) {
            leftHeader = this.course();
        }
        else if (this.props.degreeType || this.props.degreeSubject) {
            leftHeader = this.degree();
        }

        const header = React.createElement("div", { key: this.props.uniqueId + ".0", className: "FlexRow" }, [leftHeader, this.endDate()]);
        
        let details = <React.Fragment key={ this.props.uniqueId + ".1"}></React.Fragment>
        if (this.props.additionalDetails) {
            details = (<div key={this.props.uniqueId + ".2"} className="ResumeItem">
               { this.props.additionalDetails }
            </div>);
        } 

        const uniqueId = this.props.uniqueId;
        return (
            <FineTune 
                key={ uniqueId }
                fineTune={ !!this.props.filters.fineTune }
                isActive={ true }
                uniqueId={ uniqueId }
                children={ [header, details] } />
        );
    }
}

export default class EducationOrganization extends React.Component<EdO & ResumeArguments, any> {
    children: ReactElement[] = [];
    
    compileChildren(): boolean {
        // Empty this.children and rebuild
        while (this.children.length > 0) this.children.pop();
        const items = this.props.items;
        let haveChildren = false;
        for (let i = 0; i < items.length; i++) {
            if (isActive(items[i], this.props.filters)) {
                const uniqueId = this.props.uniqueId + "." + i;
                const props = Object.assign({}, items[i], { key: uniqueId, uniqueId: uniqueId, filters: this.props.filters });
                this.children.push(
                    React.createElement(Education, props, null)
                );
                haveChildren = true;
            }
        }
        return haveChildren;
    }

    render() {
        // Rebuilds children list
        if (!this.compileChildren()) return <React.Fragment key={ this.props.uniqueId + ".F" } />
        
        // Wrap children in container
        const header = (
            <OrganizationHeader
                key={ this.props.uniqueId + ".0" }
                uniqueKey={ this.props.uniqueId + ".0" }
                organization={ this.props.name }
                location={ this.props.location }
                />
        );

        const divider = <div key={ this.props.uniqueId + ".d" } className="HorizontalDivider2"></div>

        const container = React.createElement("div", { key: this.props.uniqueId + ".1", className: "Container" }, this.children);

        const props = {
            key: this.props.uniqueId,
            className: "InnerBlock",
        };

        //        style={{ position: "relative" }}>
        return (
            <div {...props}>
                <FineTune key={ props.key }
                    fineTune={ !!this.props.filters.fineTune }
                    isActive={ true }
                    uniqueId={ props.key }
                    children={ [header, divider, container] } />
            </div>
        );
    }
}