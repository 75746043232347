import React from 'react';

export default class Footer extends React.Component<{
    hideAll: boolean,
    transitionTime: number,
}, any> {
    footerRef: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidMount() {
        const footer = this.footerRef.current;
        if (footer) {
            footer.style.height = footer.offsetHeight + "px";
            footer.style.opacity = "1";
            footer.setAttribute("data-height", footer.style.height);
        }
    }

    componentDidUpdate() {
        const footer = this.footerRef.current;
        if (footer) {
            if (this.props.hideAll) {
                footer.style.height = footer.offsetHeight + "px";
                footer.setAttribute("data-height", footer.style.height);
            }
            footer.style.height = (this.props.hideAll ? "0" : footer.getAttribute("data-height") || "");
            footer.style.padding = (this.props.hideAll ? "0!important" : "");
            footer.style.opacity = (this.props.hideAll ? "0" : "1");
        }
    }

    render() {
        let year = (new Date()).getFullYear().toString();
        if (year !== "2020") {
            year = "2020 – " + year;
        }
        return (
        <footer ref={ this.footerRef } className="Footer page-footer text-center blue fixed-bottom">
            <div className="pt-3 pb-3" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                <span className="pl-1 pr-1" style={{ display: "flex", whiteSpace: "nowrap" }}>Copyright &copy; {year}</span>
                <span className="pl-1 pr-1" style={{ display: "flex", whiteSpace: "nowrap" }}>Zach Gateley</span>
                <span className="pl-1 pr-1" style={{ display: "flex", whiteSpace: "nowrap" }}>&lt; <a href="mailto: zygateley@gmail.com"> zygateley@gmail.com </a>&gt;</span>
            </div>
        </footer>
        );
    }
}