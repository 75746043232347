import React, { ReactElement } from "react";
import { SkT, Sk } from "./dataInterface";
import { isActive } from "./Resume";
import { ResumeArguments } from "./ResumeHandler";
import FineTune from "./FineTune";

export default class SkillType extends React.Component<SkT & ResumeArguments & any, any> {
    header: React.RefObject<HTMLDivElement>;
    skillBlock: React.RefObject<HTMLDivElement>;

    constructor(props: SkT & ResumeArguments & any) {
        super(props);
        this.header = React.createRef();
        this.skillBlock = React.createRef();
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    /**
     * Size skill columns.
     * Hide skill columns if FineTune made them inactive
     */
    componentDidUpdate() {
        // Update skill columns
        //      hide inactive skills, hide duplicate dividers
        const container = this.skillBlock.current;
        if (container && container.children.length > 0 && container.children[0].getAttribute("data-type") === "skill") {
            // Loop through all skills and split into FlexRows with dividers
            let cols = 0;
            let haveSkills = false;
            let lastDivider: Element | null = null;
            let lastIsDivider = false;
            Array.from(container.children).map((sk: Element) => {
                const componentHidden = sk.classList.contains("Hidden");
                if (componentHidden) {
                    sk.classList.remove("Hidden");
                }
                const dataType: string = sk.getAttribute("data-type") || "";
                if (dataType === "skill") {
                    // Skill
                    const elementHidden = sk.children[0].classList.contains("Hidden");
                    if (elementHidden) {
                        sk.classList.add("Hidden");
                    }
                    else {
                        cols++;
                        lastIsDivider = false;
                        haveSkills = true;
                    }
                }
                else if (dataType === "divider") {
                    // Divider
                    if (lastDivider && lastIsDivider) lastDivider.classList.add("Hidden");
                    lastDivider = sk;
                    lastIsDivider = true;
                    if (!haveSkills) sk.classList.add("Hidden");
                }
                return true;
            });
            // Remove trailing dividers
            if (lastDivider && lastIsDivider) (lastDivider as Element).classList.add("Hidden");

            const columnsPerRow = Math.min(
                this.props.filters.maxSkillColumns,
                Math.max(this.props.filters.minSkillColumns, cols)
            );
            const width = (100 / columnsPerRow) + "%";

            // Update skills
            Array.from(container.children)
                .filter((el: Element) => el.getAttribute("data-type") === "skill")
                .map((el: Element) => (el as HTMLElement).style.width=width);
        }

        
        // Update header column
        const header = this.header.current;
        if (header && header.nextSibling && header.parentNode) {
            const parent = header.parentNode as HTMLElement;
            const headerStyles = window.getComputedStyle(header);
            const skillBlockStyles = window.getComputedStyle(header.nextSibling as HTMLElement);
            const headerIsEmpty = parseInt(headerStyles.height) === 0;
            const skillsAreEmpty = parseInt(skillBlockStyles.height) === 0;
            if (headerIsEmpty && skillsAreEmpty) {
                parent.classList.add("Hidden");
            }
            else if (parent.classList.contains("Hidden")) {
                parent.classList.remove("Hidden");
            }
        }
    }

    render() {
        const fineTune: boolean = this.props.filters.fineTune;

        // Get an up-to-date (filtered) list of skills
        let items: Sk[] = this.props.items.filter((sk: Sk) => isActive(sk, this.props.filters));
        if (!items.length) return <React.Fragment key={ this.props.uniqueId } />

        // Header and
        // Horizontal Divider
        const labelKey = this.props.uniqueKey + ".0";
        let label = <React.Fragment key={ labelKey } />
        const dividerKey = this.props.uniqueKey + ".d";
        let horizontalDivider = <React.Fragment key={ dividerKey } />;
        if (this.props.topLevelSkills && this.props.topLevelSkills > 1) {
            label = <div key={ this.props.uniqueId + ".0" } className="Header3">
                { this.props.name }
            </div>;
            horizontalDivider = <div key={ dividerKey } className="HorizontalDivider3"></div>
        }
        const headerKey = this.props.uniqueId + ".h";
        const header = (
            <div key={ headerKey } ref={ this.header } data-type="header">
                <FineTune key={ headerKey }
                    fineTune={ fineTune }
                    isActive={ true }
                    uniqueId={ headerKey }
                    children={ [label, horizontalDivider] } />
            </div>
        );

        
        // Show all columns now
        // After render, divide into appropriate number of columns (componentDidUpdate)
        // Columns of skills
        let skills: ReactElement[] = [];
        for (let skillId = 0; skillId < items.length; skillId++) {
            const skill = items[skillId].name;
            const uniqueId = "sk." + skill;
            skills.push(
                <div key={ uniqueId } data-type="skill" className="Center Skill">
                    &nbsp;&nbsp;
                    <FineTune key={ uniqueId }
                        fineTune={ fineTune }
                        isActive={ true }
                        uniqueId={ uniqueId }
                        restoreHidden={ true }
                        children={ skill } />
                </div>
            );
            skills.push(
                <div key={ uniqueId + ".d" } data-type="divider" className="Center SkillDivider Highlight">
                    &loz;
                </div>
            )
        }
        const skillBlock = (
        <div key={ this.props.uniqueId + ".1" } 
            id={ this.props.uniqueId + ".1" }
            className="FlexRow"
            style={{ overflow: (this.props.filters.fineTune ? "visible" : "hidden") }}
            ref={ this.skillBlock }>
            { skills }
        </div>
        );
        
        const props = {
            key: this.props.uniqueId,
            className: "Block Container",
        };

        return React.createElement("div", props, [ header, skillBlock ]);
    }
}