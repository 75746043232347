import React from 'react';
import { CV, DegreeType, LatinHonors, Profile } from "./dataInterface"

let cv: CV = [
    {
        header: {
            header: "Zach Gateley",
            subheaders: [
                "(971) 429-8440",
                "zygateley@gmail.com",
                "Colorado Springs, CO 80910",
                "github.com/zacharyygateley",
                "zygateley.com",
            ],
            profiles: [Profile.cv, Profile.engineering, Profile.software],
        },
        profiles: [Profile.cv, Profile.engineering, Profile.software],
    },
    {
        title: {
            name: "Education",
            profiles: [Profile.cv, Profile.engineering, Profile.software],
        },
        profiles: [Profile.cv, Profile.software, Profile.engineering],
        education: [
            {
                name: "Arizona State University",
                location: {
                    major: "Online"
                },
                profiles: [Profile.cv, Profile.software, Profile.engineering],
                items: [
                    {
                        endDate: makeDate("2020-12"),
                        isExpected: false,
                        degreeType: DegreeType.G,
                        degreeSubject: "Computer Science",
                        gpa: "4.00",
                        profiles: [Profile.cv, Profile.software, Profile.engineering],
                        additionalDetails: "\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0Graduate Foundations of Algorithms, Data Processing at Scale, Applied Cryptography"
                    },
                ],
            },
            {
                name: "Arizona State University Continuing and Professional Education",
                location: {
                    major: "Online",
                },
                profiles: [Profile.cv],
                items: [
                    {
                        endDate: makeDate("2020-06"),
                        isExpected: false,
                        course: "Principles of Programming Languages",
                        profiles: [Profile.cv],
                    },
                    {
                        endDate: makeDate("2020-05"),
                        isExpected: false,
                        course: "Operating Systems",
                        profiles: [Profile.cv],
                    },
                    {
                        endDate: makeDate("2020-05"),
                        isExpected: false,
                        course: "Data Structures and Algorithms",
                        profiles: [Profile.cv],
                    },
                    {
                        endDate: makeDate("2020-04"),
                        isExpected: false,
                        course: "Computer Organization and Assembly Language Programming",
                        profiles: [Profile.cv],
                    }
                ],
            },
            {
                name: "University of California, Los Angeles",
                location: {
                    minor: "Los Angeles",
                    major: "California",
                },
                profiles: [Profile.cv, Profile.software, Profile.engineering],
                items: [
                    {
                        endDate: makeDate("2019-12"),
                        isExpected: false,
                        degreeType: DegreeType.MS,
                        degreeSubject: "Mechanical Engineering",
                        gpa: "3.90",
                        profiles: [Profile.cv, Profile.software, Profile.engineering],
                    },
                    {
                        endDate: makeDate("2018-12"),
                        isExpected: false,
                        degreeType: DegreeType.BS,
                        degreeSubject: "Mechanical Engineering",
                        gpa: "3.83",
                        latinHonors: LatinHonors.magnaCumLaude,
                        profiles: [Profile.cv, Profile.software, Profile.engineering],
                    },
                ],
            },
            {
                name: "San Diego Mesa College",
                location: {
                    minor: "San Diego",
                    major: "California",
                },
                profiles: [Profile.cv],
                items: [
                    {
                        endDate: makeDate("2016-05"),
                        isExpected: false,
                        degreeType: DegreeType.AA,
                        degreeSubject: "Physics",
                        gpa: "3.96",
                        profiles: [Profile.cv],
                    }
                ],
            }
        ],
    },
    {
        title: {
            name: "Work Experience",
            profiles: [Profile.cv, Profile.engineering, Profile.software],
        },
        profiles: [Profile.cv, Profile.engineering, Profile.software],
        experience: [
            {
                organization: "Microchip Technology Inc.",
                location: {
                    minor: "Colorado Springs",
                    major: "Colorado",
                },
                name: "Senior Equipment Engineer, Dry Etch",
                startDate: makeDate("2023-07"),
                additionalDetails: <table cellSpacing="5" cellPadding="0"><tbody><tr><td valign="top" className="Nowrap">Tool Ownership:&nbsp;&nbsp;</td><td>LAM 4420, LAM 4420XL, LAM 4520i, LAM 9400SE II, LAM 9400PTX, LAM Alliance</td></tr></tbody></table>,
                profiles: [Profile.cv, Profile.software, Profile.engineering],
                items: [
                    {
                        text: "Built comprehensive spare parts list to make it easier for my technicians to do their job.",
                        profiles: [Profile.cv, Profile.software, Profile.engineering]
                    },
                    {
                        text: "Rebuilt documentation and preventive maintenance for consistency across toolsets, clarity, and for data-driven scheduling.",
                        profiles: [Profile.cv, Profile.software, Profile.engineering]
                    }
                ],
            },
            {
                organization: "Analog Devices Inc.",
                location: {
                    minor: "Beaverton",
                    major: "Oregon",
                },
                name: "Site Lead, Water Withdrawal Reduction / Equipment Engineer, Wet Etch & BCD",
                startDate: makeDate("2021-01"),
                endDate: makeDate("2023-07"),
                additionalDetails: <table cellSpacing="5" cellPadding="0"><tbody><tr><td valign="top" className="Nowrap">Tool Ownership:&nbsp;&nbsp;</td><td>FSI Mercury (FEOL and BEOL), Bulk Chemical Delivery (Pressure Vessel, Blend, and Pump Designs), Rotary and Linear Wet Benches, Vacuum Bag Sealers, AMR WIP Movers, TEL Wafer Probers</td></tr></tbody></table>,
                profiles: [Profile.cv, Profile.software, Profile.engineering],
                items: [
                    {
                        text: "Spearheaded withdrawal reduction of city water by 15% (45gpm) enabling resource availability for the site's continued expansion. Over five months, my cross-disciplinary team of engineers, managers, and directors devised, tested, and executed methods of sustainable withdrawal reduction. I collaborated with directors of ADI's other manufacturing sites and reported to the managing directors of manufacturing and sustainability.",
                        // CMP, Anna Albert, Cassidy, Mike White, Latisha Paw U, Photo, Collin Vanderzanden, Ryan Bestwick, Irena Lizier-Zmudzinski, Facilities Director, Dr Jose Garcia
                        profiles: [Profile.cv, Profile.engineering],
                    },
                    {
                        text: "Improved FSI Mercury toolset uptime by 10% by standardizing fleet, overhauling and automating PM procedures, digitizing and improving documentation, and instructing and learning from maintenance team.",
                        profiles: [Profile.cv, Profile.engineering],
                    },
                    {
                        text: "Assumed responsibility of needed safety and business continuity projects that were not otherwise receiving appropriate support: chemical cabinet repair and replacement, BCD floor replacement, exhaust cleanout installations, etc.",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                    {
                        text: "Managed pre-diffusion clean linear wet bench install, interfacing with Facilities, Contractors, and Process Engineering.",
                        profiles: [Profile.cv, Profile.engineering],
                    },
                    {
                        text: "Spearheaded DI water recovery on tool whose hot DI bypass return line had been plumbed to drain on install, saving more than 40,000 gallons of DI water per year.",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                    {
                        text: "Handled equipment-side implementation and development of Omron WIP-mover Autonomous Mobile Robot installation interdisciplinarily with IT, Automation, and Production.",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                    {
                        text: "Published regular tool improvements and One Point Lessons to maintenance and chemical teams.",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                    {
                        text: "Automated data compilation and processing tasks used across teams using Python, Windows batch programming, Excel, and VBA.",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                ]
            },
            {
                organization: "L3Harris",
                location: {
                    minor: "Torrance",
                    major: "California",
                },
                name: "Manufacturing Engineering Intern",
                startDate: makeDate("2019-06"),
                endDate: makeDate("2019-08"),
                profiles: [Profile.cv, Profile.software, Profile.engineering],
                items: [
                    {
                        text: "Prepared new machine for production line by amassing all available knowledge, extensively testing and demonstrating functionality, and composing multiple detailed procedural instructions.",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                    {
                        text: "Engineered process control web site with data collection and visualization interfaces using PHP, SQL, and DyGraph.",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                    {
                        text: "Expedited order delivery to client by aiding engineers in product repair, and minimized future delays by establishing repair documentation.",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                ],
            },
            {
                organization: "Boston Scientific Corporation",
                location: {
                    minor: "Valencia",
                    major: "California",
                },
                name: "R&D Intern",
                startDate: makeDate("2018-06"),
                endDate: makeDate("2018-09"),
                profiles: [Profile.cv, Profile.engineering],
                items: [
                    {
                        text: "Designed and fabricated proof-of-concept prototypes for implanted " +
                        "neuromodulation leads and for implantation delivery using SolidWorks, " +
                        "3D printing, machine tools, and cleanroom microfabrication equipment.",
                        profiles: [Profile.cv, Profile.engineering],
                    },
                    {
                        text: "Employed industry-standard equipment for strength and quality testing.",
                        profiles: [],
                    },
                ],
            },
            {
                organization: "Cykic Software, Inc",
                location: {
                    minor: "San Diego",
                    major: "California",
                },
                name: "Senior Full-Stack Web Developer",
                startDate: makeDate("2006-08"),
                endDate: makeDate("2016-06"),
                profiles: [Profile.cv, Profile.software, Profile.engineering],
                items: [
                    {
                        text: "Proposed and authored front-end design and co-authored back-end interface of major upgrade to crucial organization tool using JavaScript, CSS, and HTML5.",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                    {
                        text: "Produced content management system cooperatively with another senior developer, which powers 90% of client websites and utilizes JavaScript, CSS, HTML5, JSON, XML, and proprietary back-end dBase language.",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                    {
                        text: "Automated quotations, delivery POS, IT ticketing, and PTO requests for a Southern California transportation client.",
                        profiles: [Profile.cv, Profile.software],
                    },
                    {
                        text: "Developed custom, multi-tiered, permission-driven content sharing application for communication between elementary school teachers and families. Provided regular ongoing technical support with the client.",
                        profiles: [Profile.cv, Profile.software],
                    },
                ],
            },
        ],
    },
    {
        title: {
            name: "Project Experience",
            profiles: [Profile.cv, Profile.engineering, Profile.software],
        },
        profiles: [Profile.cv, Profile.engineering, Profile.software],
        experience: [
            {
                organization: "Compiler and Python Transpiler",
                location: {
                    minor: "Evanston",
                    major: "Illinois",
                },
                name: "Personal Project",
                URL: "https://github.com/ZacharyYGateley/Compiler",
                startDate: makeDate("2020-06"),
                endDate: makeDate("2020-07"),
                profiles: [Profile.cv, Profile.software, Profile.engineering],
                items: [
                    {
                        text: "Built a compiler in Java for an ad hoc weakly-typed programming language with output in x86 assembly.",
                        profiles: [Profile.cv, Profile.software, Profile.engineering],
                    },
                    {
                        text: "Engineered a compiler front-end complete with a lexer, which transmutes a source file into a token stream and symbol table, and parser, which translates the stream into a syntax tree.",
                        profiles: [Profile.cv, Profile.software, Profile.engineering],
                    },
                    {
                        text: "Devised optimization algorithms, implicit type checker, and respective generalized Java code, which when applied to a syntax tree minimizes and standardizes while maintaining equivalence.",
                        profiles: [Profile.cv, Profile.software],
                    },
                    {
                        text: "Developed core assembly language procedures and a twice-abstracted assembler, which handles Windows API methods, type promotion, and multiply-scoped, automatic garbage collection.",
                        profiles: [Profile.cv, Profile.software, Profile.engineering],
                    },
                    {
                        text: "Constructed a Python transpiler add-on module for this compiler.",
                        profiles: [Profile.cv, Profile.software],
                    },
                ],
            },
            {
                organization: "Coursera",
                location: {
                    minor: "Los Angeles",
                    major: "California",
                },
                name: "Neural Network Python Module",
                URL: "https://github.com/ZacharyYGateley/MachineLearning",
                endDate: makeDate("2019-08"),
                profiles: [Profile.cv, Profile.software],
                items: [
                    {
                        text: "Developed basic, functional, arbitrarily deep neural network Python module using NumPy with sigmoid, tanh, ReLU, and leaky ReLU activations. No external machine learning module was used.",
                        profiles: [Profile.cv, Profile.software],
                    },
                ],
            },
            {
                organization: "University of California, Los Angeles",
                location: {
                    minor: "Los Angeles",
                    major: "California",
                },
                name: "Custom 5-DOF Robotic Arm",
                startDate: makeDate("2019-04"),
                endDate: makeDate("2019-06"),
                profiles: [Profile.cv, Profile.engineering],
                items: [
                    {
                        text: "Assembled group's 5-DOF PUMA-style cup-filling robot arm using Dynamixel actuators and 3D printed parts.",
                        profiles: [Profile.cv, Profile.engineering],
                    },
                    {
                        text: "Implemented autonomous decentralized independent joint-space control system in MATLAB and Arduino.",
                        profiles: [Profile.cv, Profile.engineering],
                    },
                ],
            },
            {
                organization: "University of California, Los Angeles",
                location: {
                    minor: "Los Angeles",
                    major: "California",
                },
                name: "Variable Stiffness Metamaterial",
                startDate: makeDate("2019-02"),
                endDate: makeDate("2019-03"),
                profiles: [Profile.cv],
                items: [
                    {
                        text: "Conceptualized and helped evolve compliant mechanism for variable stiffness metamaterial.",
                        profiles: [Profile.cv],
                    },
                    {
                        text: "Performed critical geometric analysis for multiple-parameter unit cell design considerations.",
                        profiles: [Profile.cv],
                    },
                    {
                        text: "Collaborated to deliver SolidWorks part and assembly solutions to ANSYS FEA team for design analysis and iteration.",
                        profiles: [Profile.cv],
                    },
                    {
                        text: "Researched integrated-actuation fabrication plausibility and identified promising additive manufacturing pathways.",
                        profiles: [Profile.cv],
                    },
                ],
            },
            {
                organization: "University of California, Los Angeles",
                location: {
                    minor: "Los Angeles",
                    major: "California",
                },
                name: "Autonomous Projectile-Launching Robot",
                startDate: makeDate("2018-01"),
                endDate: makeDate("2018-06"),
                profiles: [Profile.cv, Profile.engineering],
                items: [
                    {
                        text: "Earned one of eight national finalist positions in 2018 ASME Student Manufacturing Design Competition.",
                        profiles: [Profile.cv, Profile.engineering],
                    },
                    {
                        text: "Designed compact, gear-driven projectile launcher and trigger mechanisms to tight tolerances in SolidWorks for easy manufacture and assembly.",
                        profiles: [Profile.cv, Profile.engineering],
                    },
                    {
                        text: "Delivered successful electrical solution for team's complex mechatronic systems.",
                        profiles: [Profile.cv, Profile.engineering],
                    },
                    {
                        text: "Debugged and assisted development of autonomy state chart in LabVIEW.",
                        profiles: [Profile.cv, Profile.engineering],
                    },
                ],
            },
            {
                organization: "Pontificial Catholic University of Chile",
                location: {
                    minor: "Santiago",
                    major: "Chile",
                },
                name: "Design and Construction of Synchronous Electric Motor",
                startDate: makeDate("2017-10"),
                endDate: makeDate("2017-11"),
                profiles: [],
                items: [
                    {
                        text: "Collaborated with electric engineering students to apply classroom knowledge towards building a three-phase synchronous electric generator / motor with electronic control.",
                        profiles: [Profile.cv],
                    },
                ],
            },
            {
                organization: "ASME at UCLA",
                location: {
                    minor: "Los Angeles",
                    major: "California",
                },
                name: "Fabrication and Design Essentials (FADE) Program",
                startDate: makeDate("2016-09"),
                endDate: makeDate("2017-06"),
                profiles: [],
                items: [
                    {
                        text: "Learned CAD, machine shop, and electric circuitry by one-on-one hands-on training with student officers.",
                        profiles: [Profile.cv],
                    },
                    {
                        text: "Earned second place with functional solid model and CAD drawing of clock assembly incorporating components from SolidWorks Toolbox and McMaster-Carr.",
                        profiles: [Profile.cv],
                    },
                    {
                        text: "Earned third place collaboratively machining a pre-specified, detailed aluminum part.",
                        profiles: [Profile.cv],
                    },
                ],
            },
        ],
    },
/*
    {
        title: {
            name: "Volunteering Experience",
            profiles: [Profile.cv, Profile.engineering, Profile.software],
        },
        profiles: [Profile.cv, Profile.engineering, Profile.software],
        experience: [
            {
                organization: "Personal Accomplishment",
                location: {
                    minor: "Denver",
                    major: "Colorado",
                },
                name: "",
                startDate: makeDate("2019-12"),
                endDate: makeDate("2020-03"),
                profiles: [Profile.cv, Profile.engineering, Profile.software],
                items: [
                    {
                        text: "Effected recovery of a family friend's home and financial situation, ultimately resulting in $31,000/yr savings.",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                    {
                        text: "Coordinated and executed the consolidation of storage facilities with sensitivity to items of personal value, resulting in $1,200/mo savings.",
                        profiles: [Profile.cv],
                    },
                    {
                        text: "Supervised identification of new living space that satisfied all requirements and desires, assisted application process, and directed as well as executed the move, resulting in $1,200/mo savings.",
                        profiles: [Profile.cv],
                    },
                    {
                        text: "Further identified and facilitated abatement of key expenses by finding appropriate solutions or replacements, resulting in $220/mo savings.",
                        profiles: [Profile.cv],
                    },
                ],
            },
        ],
    },
*/
    {
        title: {
            name: "Highlighted Skills",
            profiles: [Profile.cv, Profile.engineering, Profile.software],
        },
        profiles: [Profile.cv, Profile.engineering, Profile.software],
        skills: [
            // {
            //     name: "Solid Modeling and CAD",
            //     profiles: [Profile.cv, Profile.engineering],
            //     items: [
            //         {
            //             name: "SolidWorks",
            //             profiles: [Profile.cv, Profile.engineering],
            //         },
            //     ],
            // },
            {
                name: "",
                profiles: [Profile.cv, Profile.engineering, Profile.software],
                items: [
                    {
                        name: "HTML5",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                    {
                        name: "JavaScript",
                        profiles: [Profile.cv, Profile.engineering, Profile.software],
                    },
                    {
                        name: "CSS3",
                        profiles: [Profile.engineering],
                    },
                    {
                        name: "CSS3, SCSS",
                        profiles: [Profile.cv, Profile.software],
                    },
                    {
                        name: "Technical Documentation",
                        profiles: [Profile.cv, Profile.engineering]
                    },
                    {
                        name: "Wiring Diagrams",
                        profiles: [Profile.cv, Profile.engineering]
                    },
                    {
                        name: "Pneumatic Diagrams",
                        profiles: [Profile.cv, Profile.engineering]
                    },
                    {
                        name: "KPI, 8D, 5S",
                        profiles: [Profile.cv, Profile.engineering]
                    },
                    {
                        name: "Excel VBA",
                        profiles: [Profile.cv, Profile.engineering]
                    },
                    {
                        name: "SolidWorks",
                        profiles: [Profile.cv, Profile.engineering],
                    },
                    {
                        name: "Python",
                        profiles: [Profile.engineering],
                    },
                    {
                        name: "Java",
                        profiles: [Profile.engineering],
                    },
                    {
                        name: "MATLAB",
                        profiles: [Profile.engineering],
                    },
                    {
                        name: "ReactJS",
                        profiles: [Profile.cv, Profile.software],
                    },
                    {
                        name: "TypeScript",
                        profiles: [Profile.cv, Profile.software],
                    },
                    {
                        name: "Java",
                        profiles: [Profile.cv, Profile.software],
                    },
                    {
                        name: "Python",
                        profiles: [Profile.cv, Profile.software],
                    },
                    {
                        name: "MATLAB",
                        profiles: [Profile.cv, Profile.software],
                    },
                    {
                        name: "SQL",
                        profiles: [Profile.cv, Profile.software, Profile.engineering],
                    },
                    {
                        name: "Git",
                        profiles: [Profile.cv, Profile.software, Profile.engineering],
                    },
                    {
                        name: "x86 Assembly",
                        profiles: [Profile.cv, Profile.engineering],
                    },  
                ],
            },
        ],
    },
];

function makeDate(date: string) {
    return new Date(date + "-01T12:00:00");
}

export default cv;