import React, { ReactElement } from "react";
import { Hd } from "./dataInterface";
import { ResumeArguments } from "./ResumeHandler";
import FineTune from "./FineTune";

export default class Header extends React.Component<Hd & ResumeArguments, any> {
    get compact(): ReactElement<HTMLElement> {
        // Centered header and first two subheaders
        let children: ReactElement[] = [];
        let subheaders = this.props.subheaders;
        for (let i = 0; i < this.props.subheaders.length; i++) {
            if (i > 0) {
                children.push(
                    <div key={ this.props.uniqueId + "." + (i) + ".d" } className="Center Flex InlineDivider" 
                        style={{ width: "20px" }}>
                        |
                    </div>
                )
            }
            children.push(
                <div key={ this.props.uniqueId + "." + (i + 1)} className="Center Flex SubtitleCompact Header3 Hd0">
                    { subheaders[i] }
                </div>
            );
        }

        
        return (
            <div key={ this.props.uniqueId + ".0" } style={{ "margin": "auto" }}>
                <div key={ this.props.uniqueId + ".0" } className="Title Highlight">{ this.props.header }</div>
                <div className="Center FlexRow Nowrap" style={{ width: "fit-content", margin: "0 auto" }}>
                    { children }
                </div>
            </div>
        );
    }

    get center(): ReactElement<HTMLElement> {
        // Centered header and first two subheaders
        let subheader0 = "", subheader1 = "";
        let sub = this.props.subheaders;
        if (sub) {
            if (sub.length > 0) {
                subheader0 = this.props.subheaders[0];
            }
            if (sub.length > 1) {
                subheader1 = this.props.subheaders[1];
            }
        }
        
        return (
            <div key={ this.props.uniqueId + ".0" } style={{ "margin": "auto" }}>
                <div key={ this.props.uniqueId + ".0" } className="Title">{ this.props.header }</div>
                <div key={ this.props.uniqueId + ".1" } className="Center Subtitle Highlight">{ subheader0 }</div>
                <div key={ this.props.uniqueId + ".2" } className="Center Subtitle Highlight">{ subheader1 }</div>
            </div>
        );
    }

    get right(): ReactElement<any> {
        let sub = this.props.subheaders;

        if (!sub || sub.length < 3) return <React.Fragment key="-1"></React.Fragment>

        let children: ReactElement<any>[] = [];
        for (let i = 2; i < sub.length; i++) {
            children.push(<div key={ this.props.uniqueId + ".3." + i } className="Right Subtitle Highlight">{ sub[i] }</div>);
        }

        let props = { 
            key: this.props.uniqueId + ".1", 
            style: { "position": "absolute", "right": "0", "bottom": "0" }
        }

        return React.createElement("div", props, children);
    }

    render() {
        let children: ReactElement<any>[] = [];
        if (this.props.filters.compactHeader) {
            children.push(this.compact);
        }
        else {
            children.push(this.center); 
            children.push(this.right);
        }

        const uniqueId = this.props.uniqueId + ".ft";
        return (<FineTune key={ uniqueId }
            fineTune={ !!this.props.filters.fineTune }
            isActive={ true }
            uniqueId={ uniqueId }
            children={ children } />);
    }
}