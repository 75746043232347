import React, { ReactElement } from "react";
import { CSSTransition } from "react-transition-group";
import "./styles/FineTune.sass"

/**
 * Wrapper for any item that can be added or removed from the resume/cv
 * When !!fineTune, may click to remove/re-add
 */
export default class FineTune extends React.Component<any, any> {
    fromRefresh: boolean = true;

    constructor(props: { children?: ReactElement[], fineTune: boolean, isActive: boolean, uniqueId: any }) {
        super(props);
        this.state = {
            isActive: props.isActive,
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        // If state did not change, need to refire transition
        // But only care if fine-tuning (has transitions)
        if (this.props.fineTune) {
            const el = document.getElementById(this.props.uniqueId);
            if (!el) return;

            let changedState = Object.entries(prevState).filter((pair: [string, unknown]) => {
                let [key, value] = pair;
                return this.state[key] !== value;
            }).length > 0;

            if (!changedState && !this.state.isActive) {
                // Must emulate refired transition
                el.className="Tuner Tuner-exit-done";
            }
        }
    }

    /*
    componentWillUpdate(prevProps: any) {
        for (let prop in prevProps) {
            if (prevProps[prop] !== this.props[prop]) {
                this.fromRefresh = true;
                break;
            }
        }
    }
    */

    handleToggle(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            isActive: !this.state.isActive,
        });
    }

    render() {
        let classNames = "TunerRO" + (this.state.isActive ? "" : " Hidden");
        let onClick: Function = () => {};
        const nextKey = this.props.uniqueId + ".0"
        if (this.props.fineTune) {
            /*
            if (!this.state.isActive && this.fromRefresh) {
                window.setTimeout(() => {
                    const el = document.getElementById(this.props.uniqueId);
                    if (el) {
                        el.className="Tuner Tuner-exit-done";
                    }
                }, 10);
            }
            */
            this.fromRefresh = false;
            classNames = "Tuner Tuner";
            onClick = (event: React.MouseEvent<HTMLDivElement>) => this.handleToggle(event);
        }

        let restoreHidden = !this.props.fineTune || !!this.props.restoreHidden;

        return (
            <CSSTransition 
                in={ this.state.isActive }
                key={ nextKey }
                classNames={ classNames }
                timeout={ 400 }>
                <div key={ nextKey } id={ this.props.uniqueId } className={ classNames } onClick={ (event: React.MouseEvent<HTMLDivElement>) => onClick(event) }>
                    <div key="0" className={ classNames + "Content" }>
                        { this.props.children }
                    </div>
                    <div key="1" className={ classNames + "Restore" + (restoreHidden ? " Hidden" : "") }>
                        <div style={{ position: "relative" }}>
                            <div></div>
                            <div>
                                Restore
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }
}