import React from 'react';
import Resume from "./Resume";
import MenuDock from "./MenuDock";
import cv from "./data";
import { Profile } from "./dataInterface";
import './styles/transitions.scss';
import { CSSTransition } from 'react-transition-group';
import { getCSSClass } from './Utils';
import { calculatePageHeight } from '../Page';
import { Button, Modal } from 'react-bootstrap';

const defaultMinSkillColumns = 3;
const defaultMaxSkillColumns = 3;
const transitionTime = "400ms";
let lastOuterWidth = 0;

export type Filters = {
  profile: Profile,
};

export type ResumeArguments = {
  uniqueId: any,
  filters: {
    compactHeader: boolean,
    fineTune: boolean,
    minSkillColumns: number,
    maxSkillColumns: number,
    profile: Profile,
  },
};

export default class ResumeHandler extends React.Component<any, any> {
  reopenHooks: Function[] = [];
  swipeRoot: React.RefObject<HTMLDivElement> = React.createRef();
  swipeNext: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: any) {
    super(props);
    this.state = {
      alertMobile: false,
      compactHeader: false,
      fineTune: false,
      minSkillColumns: defaultMinSkillColumns,
      maxSkillColumns: defaultMaxSkillColumns,
      printerFriendly: true,
      profile: Profile.cv,
      visible: true,
    }
  }

  componentDidMount() {
    document.title = "Zach Gateley - Resume";
    this.updateViewStyle(true);
    this.setState({ printerFriendly: false });
    window.addEventListener("resize", () => this.updateViewStyle(false));
    if (window.innerWidth < 740) {
      this.handleModal(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.updateViewStyle(false));
  }

  handleFineTuning(fineTune: boolean) {
    this.setState({ 
      fineTune: fineTune,
    })
  }

  handleModal(open: boolean) {
    this.setState({
      alertMobile: open,
    })
  }

  handlePrinterFriendly(printerFriendly: boolean) {
    if (this.props.handleHideAll) {
      this.props.handleHideAll(printerFriendly);
    }

    this.handleStateChange("printerFriendly", printerFriendly);
  }

  handleRefresh = () =>  {
    this.updateViewStyle(false);
    this.setState({ visible: false });
  }

  handleReopen = async () => {
    if (!this.state.visible) {
      // Closed about to open
      while (this.reopenHooks.length) {
        const hook = this.reopenHooks.pop()
        await Promise.resolve(hook && hook());
      }
      // Update view height after promises
      this.updateViewStyle(false);
      this.setState({
        visible: true,
      });
      // Unset res width
      window.setTimeout(() => this.updateViewStyle(true), parseInt(transitionTime));
    }
    else {
      // open about to close
      this.updateViewStyle(false);
    } 
  }

  /**
   * Generalized state change handler
   * 
   * @param key in state
   * @param value of key in state
   */
  handleStateChange(key: string, value?: any) {
    const nextState: any = {};
    nextState[key] = (value ? value : !this.state[key]);
    if (this.state[key] !== nextState[key]) {
      this.setState(nextState);
    }
  }

  handleStyleChange(className: string, styleName: string, value: string) {
    let rule = getCSSClass(className);
    if (rule) {
      let styleRule: CSSStyleRule & any = rule.style;
      styleRule[styleName] = value;
      styleRule.transition = `all ${transitionTime}`;
    }
  }

  updateViewStyle(resetWidth: boolean) {
    // Ignore zooming
    if (!resetWidth && window.outerWidth === lastOuterWidth) return;
    lastOuterWidth = window.outerWidth;

    const el = this.swipeRoot.current as HTMLDivElement;
    const next = this.swipeNext.current as HTMLDivElement;

    if (el && next) {
      const scale = Math.min(window.innerWidth / 740, 1);
      console.log("Scale: " + scale);
      console.log("Innerwidth: " + window.innerWidth);
      next.style.width = (resetWidth ? "auto" : 739 + "px");
      //const nextStyle = window.getComputedStyle(next);
      //el.style.height = (parseInt(nextStyle.height) * scale) + "px";
      console.log("offsetheight: " + next.offsetHeight);
      el.style.height = (next.offsetHeight * scale) + "px";
      el.style.width = (740) + "px";
      el.style.transform = "scale(" + scale + ")";
    }

    calculatePageHeight();
  }


  render() {
    const events = { 
      handleFineTuning: (setFineTune: boolean) => {
        this.reopenHooks.push(() => this.handleFineTuning(setFineTune));
        this.handleRefresh();
      },
      handlePrinterFriendly: (printerFriendly: boolean) => {
        this.handlePrinterFriendly(printerFriendly);
      },
      handleStateChange: (key: string, value: string) => this.handleStateChange(key, value),
      handleStateChangeRefresh: (key: string, value: string) => {
        this.reopenHooks.push(() => this.handleStateChange(key, value));
        this.handleRefresh();
      },
      handleStyleChange: (className: string, styleName: string, value: string) => this.handleStyleChange(className, styleName, value),
    }

    return (
      <div style={{ position: "relative", width: "100%" }}>
        <CSSTransition
          in={ this.state.printerFriendly }
          timeout={ parseInt(transitionTime) }
          classNames="Translate"
          className="Body"
          >
          <div key="0">
            <CSSTransition 
              in={ this.state.visible } 
              timeout={ parseInt(transitionTime) } 
              classNames="Swipe"
              onEntered={ this.handleReopen }
              onExited={ this.handleReopen }>
              <div id="ResumeSwipeRoot" ref={ this.swipeRoot }>
                <div id="ResumeSwipeNext" ref={ this.swipeNext }>
                  <div>
                    <Resume key="0" cv={ cv } filters={ this.state } /> 
                  </div>
                </div>
              </div>
            </CSSTransition>

          </div>
        </CSSTransition>
        <MenuDock 
          cv={ cv } 
          filters={ this.state } 
          events={ events } />

        <Modal show={ this.state.alertMobile } onHide={ () => this.handleModal(false) }>
          <Modal.Header closeButton>
            <Modal.Title>Hey, mobile user!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Just so you know...
            <br /><br />
            This CV is interactive on larger screens!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={ () => this.handleModal(false) }>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}