import React, { ReactElement } from 'react';
import "./styles/ProjectDetails.scss";

export default function Accord2003(): ReactElement {
    return (
        <div className="p-2 text-justify">
            <div key="0" className="text-center p-3" style={{ fontSize: "2em", fontWeight: 300 }}>
                2003 Honda Accord
            </div>
            <p key="1">
                I had done a little work on cars before this one. This is the first time I have made a car my own.
                Since purchasing this car in 2018, in my garage I have....
            </p>
            <ul>
                <li>Replaced both door lock actuators.</li>
                <li>Troubleshot and repaired failed horn.</li>
                <li>Replaced the timing belt and water pump.</li>
                <li>Replaced all motor and transmission mounts.</li>
                <li>Restored the headlight assembly and upgraded lamps to LED.</li>
                <li>Tuned the input/output valve lash.</li>
                <li>Installed and wired a backup camera.</li>
                <li>Rebuilt the power steering pump.</li>
                <li>Replaced the alternator.</li>
                <li>Rebuilt front-end suspension, including strut assemblies, bushings, and ball joints.</li>
                <li>Replaced the head liner.</li>
                <li>Replaced failed cv axle.</li>
                <li>Added auxiliary radio input with bluetooth chip using non-plug-n-play parts.</li>
                <li>Troubleshot and repaired improperly firing cabin lights.</li>
                <li>And other various odds and ends...</li>
            </ul>
            <p key="2">
                It took a few months after graduating with engineering degrees to realize that I understand the various systems of my car. 
                This quickly went from replacing needed failed parts to being sad that there was nothing wrong with the car!
                Before I knew it, I was already buying another junker to fix up.
            </p>
            <p key="3" className="text-center p-3" style={{ fontSize: "2em", fontWeight: 300 }}>
                A couple interesting problems.
            </p>
            <p key="4">
                <div className="KeyPoint">1</div>
                When I had first bought the car, the horn didn't work. At the beginning of the pandemic and finally out of school, I started
                to trace it down. The fuses were good; I had checked these after I first bought the car. The horn relay in the 
                steering wheel proved to be OK. Finally, I tested my multimeter all the way down the line to the horn. I had been 
                avoiding this in the past because to reach the horn, you have to pull the front bumper. Lo and behold, the leads are getting 
                voltage. With a new horn installed, I can safely honk my way down the road.
            </p>
            <p key="5">
                <div className="KeyPoint">2</div>
                The weekend that I replaced the timing belt, I also rebuilt the power steering pump and replaced the transmission filter.
                Fixing many problems is great, except for when you create new problems! This is something I have learned over and again as an equipment engineer as well.
                On firing the engine up, my dash happily presented me with a check engine light. 
                The code stated that there was a misfire, first on cylinders 1, 4, and 6, then shortly afterwards on all cylinders.
                <br /><br />
                <div className="KeyPoint">&nbsp;</div>My first hunch was that something didn't go right during the timing belt job. 
                Was the timing off? Had I missed reconnecting a ground? Did I cut a wire?
                I stepped back through the timing belt job, making sure that the engine was correctly timed and everything put back together correctly.
                When all proved OK and after scratching my head for a while, I decided to step through methodically focused on the check engine light.
                Engine firing order is 1, 4, 2, 5, 3, 6, so the misfires on 1, 4, 6 center around cylinder 1.
                Is this cylinder misfiring? With the car running, I disconnect and reconnect the ignition coils one-by-one. 
                The only one that doesn't make the engine stutter differently is cylinder 1. Perfect, we have the problem child.
                Are the cylinders getting spark? After fiddling through the voltages across the pins on the ignition coils on the good cylinders 
                and figuring out what the voltages are supposed to be, I confirmed that cylinder 1 was indeed getting voltage.
                What about the spark plugs and ignition coils themselves? Swapping them with a good cylinder still results in bad ignition on cylinder 1, 
                confirmed by disconnecting and reconnecting ignition coils one-by-one as before.
                It seems truly unlikely that the work I had done had affected compression, so it must be missing fuel. Where in the world are those injectors?
                While running my hands through the area underneath the intake manifold, I find the electrical connector for cylinder 1. 
                As I play with it, I realize it pushes in another centimeter but doesn't click into place. Fire the car up, and it runs like a champ, no misfires.
                The plastic clip on the electrical connector had broken at some point. 
                During all the work, I had snagged it&mdash;it sits next to the power steering pump&mdash;and pulled it out enough to lose connectivity.
            </p>
        </div>
    );
}

function example(image_src: string, caption_text?: string) {
    const children = [];

    children.push(<img src={ "/images/projects/" + image_src } alt="" />)

    if (caption_text !== "") {
        children.push(<br />)
        children.push(<span>{ caption_text }</span>)
    }

    return (
        <p className={ "ProjectImage text-center" } children={ children } />    
    );
}