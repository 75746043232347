import React from 'react';
import Dock from 'react-dock';
import Menu from "./Menu";
import "./styles/Menu.sass";

export default class MenuDock extends React.Component {
    escapeMenu = null;
    keyEvent = (event) => this.handleKeyDown(this, event);

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
        }

        this.escapeMenu = React.createRef();
    }

    componentDidMount() {
        (document.body || document.documentElement).addEventListener("keydown", this.keyEvent);
        if (window.innerWidth > 739) {
            this.setState({
                isVisible: true
            });
        }
    }

    componentWillUnmount() {
        (document.body || document.documentElement).removeEventListener("keydown", this.keyEvent);
    }
    
    handleKeyDown(self, event) {
        if (window.innerWidth < 740) return;
        event = event || window.event;
        let keyCode = event.which || event.keyCode;
        if (keyCode === 27) {
            this.handleToggle();
        }
    }

    handleToggle() {
        let esc = (this.escapeMenu ? this.escapeMenu.current : null);
        if (esc) {
            (function(esc, isVisible) { 
                return function() {
                    esc.className = "MenuEscape MenuEscape" + (isVisible ? "Close" : "Open")
                }
            })(esc, this.state.isVisible)();
        }
        this.setState({ isVisible: !this.state.isVisible })
    }

    render() {
        return (
        <React.Fragment>
            <Dock 
                key="0" 
                fluid={ false }
                size={ 390 } 
                position="right" 
                isVisible={ this.state.isVisible }
            >
                <div key="-1" ref={ this.escapeMenu } className="MenuEscape MenuEscapeOpen" onClick={ () => this.handleToggle() }>
                    <div></div>
                    <div>Press <span>Esc</span> to toggle the menu</div>
                </div>
                <div className="Menu" style={{ position: "relative" }}>
                    <div key="0" className="MenuHeader">
                        <div style={{ width: "100%", position: "relative" }}>
                            Options
                        </div>
                    </div>
                    <Menu {...this.props} />
                </div>
            </Dock>
        </React.Fragment>
        );
    }
}